const MainContent = () => {
  return (
    <>
      <div className="containerIT">
        <div className="centre-top-top">
          {" "}
          <p className="h2onwhite">We got married!</p>
        </div>
        {/* <div className="centre-top">
          <p className="h1onwhite">MARRIED</p>
        </div> */}
        <div className="bottom">
          {" "}
          <div className="banana">
            <p>Applecross Peninsula | 22nd July 2023</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContent;

import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./css/App.css";
import MainContent from "./components/MainContent";
import MyNavBar from "./components/NavBar";
import Logistics from "./components/Logistics";
import RSVP from "./components/RSVP";
import Footer from "./components/Footer";
import Activities from "./components/Activities";
import GiftList from "./components/GiftList";

function App() {
  return (
    <div className="MyApp">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <div className="content-wrap">
                  {/* <MyNavBar /> */}
                  <MainContent />
                </div>
                <Footer />
              </>
            }
          />
          {/* <Route
            path="/wedding"
            element={
              <>
                <div className="content-wrap">
                  <MyNavBar />
                  <Logistics />
                </div>
                <Footer />
              </>
            }
          />
          <Route
            path="/rsvp"
            element={
              <>
                <div className="content-wrap">
                  <MyNavBar />
                  <RSVP />
                </div>
                <Footer />
              </>
            }
          />
          <Route
            path="/activities"
            element={
              <>
                <div className="content-wrap">
                  <MyNavBar />
                  <Activities />{" "}
                </div>
                <Footer />
              </>
            }
          />
          <Route
            path="/giftlist"
            element={
              <>
                <div className="content-wrap">
                  <MyNavBar />
                  <GiftList />{" "}
                </div>
                <Footer />
              </>
            }
          /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
